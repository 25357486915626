.office-desks {
    padding-top: 126px;
    padding-left: 6.6%;
    padding-right: 6.6%;
    position: relative;
}

.office-desks .col {
    margin-bottom: 127px;
}

.office-desks h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 126px;
}

.office-desks-card {
    cursor: pointer;
}

.office-desks-card img {
    width: 100%;
    height: 264px;
    margin-bottom: 16px;
}

.office-desks-card p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 20.486px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media all and (min-width:1540px) {
    .office-desks {
        padding-left: 24.6%;
        padding-right: 24.6%;
    }

    .office-desks-card img {
        width: 100%;
        margin-bottom: 16px;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .office-desks {
        padding-top: 94px;
        padding-left: 40px;
        padding-bottom: 0px;
        padding-right: 40px;
    }

    .office-desks h2 {
        font-size: 26px;
    }
}

@media all and (max-width:767px) {
    .office-desks {
        padding: 38px;
        padding-bottom: 0px;
    }

    .office-desks h2 {
        font-size: 16px;
        margin-bottom: 40px;
    }

    .office-desks .col {
        margin-bottom: 50px;
    }

    .office-desks-card p {
        font-size: 12.92px;
        margin: 0;
    }
}