.link {
    padding-left: 6.6%;
    padding-top: 77px;
    color: var(--nutral-color, #CCC);
    font-family: Roboto Slab;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 24px;

    &.ar {
        padding-left: unset;
        padding-right: 6.6%;
    }
}

.sample-hero {
    border-top: 1px solid rgb(193, 192, 192);
    padding-top: 126px;
    padding-bottom: 126px;
    border-bottom: 1px solid rgb(193, 192, 192);
}

.sample-hero.ar .img {
    padding-left: 0px;
    padding-right: 90px;
}

.sample-hero .img {
    width: 100%;
    height: 350px;
    padding-left: 90px;

}

.sample-hero .img img {
    height: 100%;
}

.sample-hero.ar .text,
.sample-hero.ar .text h2 {
    font-family: "Rubik";
}

.sample-hero .text {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    padding-left: 100px;
    padding-right: 100px;
}

.sample-hero .text h2 {
    font-family: Roboto Slab;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;
}

.sample-hero .text p {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 54px;
}

.sample-hero .text ul {
    font-size: 18px;
    font-weight: 300;
}

.sample-hero .text ul li:not(:last-child) {
    margin-bottom: 10px;
}

@media all and (min-width:1901px) {
    .link {
        padding-left: 31.4%;

        &.ar {
            padding-left: unset;
            padding-right: 31%;
        }
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .link {
        padding-left: 25.4%;

        &.ar {
            padding-left: unset;
            padding-right: 24.7%;
        }
    }
}

@media all and (min-width:1200px) and (max-width:1520px) {
    .link {
        margin-left: 1%;

        &.ar {
            margin-left: 0%;
            margin-right: 1%;
        }
    }
}

@media all and (max-width: 767px) {
    .link {
        padding-left: 38px;
        padding-top: 35px;
        font-size: 10px;
        margin-bottom: 16px;

        &.ar {
            padding-left: 0px;
            padding-right: 38px;
        }
    }

    .sample-hero .img {
        height: 100%;
    }

    .sample-hero {
        padding: 40px;
        padding-bottom: 16px;

        &.ar {
            padding: 40px !important;
        }
    }

    .sample-hero.ar .img {
        padding-right: 0px;
    }

    .sample-hero .img {
        width: 100%;
        padding-left: 0px;
        margin-bottom: 27px;

    }

    .sample-hero .text {
        padding-right: 0px;
        padding-left: 0px;
        padding-right: 0;
        padding-bottom: 0px;

    }

    .sample-hero .text:first-of-type {
        margin-top: 10px;
    }

    .sample-hero .text h2 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .sample-hero .text p {
        font-size: 10px;
        margin-bottom: 20px;
    }

    .sample-hero .text ul {
        font-size: 10px;
    }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .link {
        padding-left: 50px;

    }

    .sample-hero {
        padding-top: 104px;
        padding-bottom: 104px;
    }


    .sample-hero .img {
        width: 100%;
        height: 100%;
        padding-left: 38px;

    }

    .sample-hero .text h2 {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .sample-hero .text p {
        font-size: 14px;
        margin-bottom: 30px;
    }

    .sample-hero .text ul {
        font-size: 14px;
    }

    .sample-hero.ar .text {
        font-family: "Rubik";
    }

    .sample-hero .text {
        color: var(--primery-color, #444484);
        font-family: Roboto;
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 0px;
    }

    .sample-hero .text ul li:not(:last-child) {
        margin-bottom: 10px;
    }


}