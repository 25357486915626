.where-design {
    padding-left: 6.6%;
    padding-right: 0px;
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;

    &.ar {
        font-family: "Rubik";
        padding-right: 6.6%;
        padding-left: 0px;
    }
}

.where-design h2 {
    font-size: 30px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 126px;

}

.where-design h2::after {
    content: "";
    display: flex;
    justify-content: flex-end;
    width: 64%;
    height: 1px;
    background: #CCC;
    margin-left: 20px;
}

.where-design.ar .text {
    padding-left: 110px;
    padding-right: 0px;
    text-align: right;
}

.where-design .text {
    text-align: left;
    padding-right: 110px;
    padding-bottom: 126px;
}

.where-design.ar .text p {
    font-family: "Rubik";
}

.where-design .text p {
    font-family: Roboto;
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;
}

@media all and (min-width:1901px) {
    .where-design {
        padding-left: 31%;

        &.ar {
            padding-right: 31%;
            padding-left: 0px;
        }
    }

    .where-design.ar .text {
        padding-right: 0px;
        padding-left: 45%;
    }

    .where-design .text {
        padding-right: 45%;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .where-design,.about-hero {
        padding-left: 25%;

        &.ar {
            padding-right: 25%;
            padding-left: 0px;
        }
    }

    .where-design.ar .text {
        padding-right: 0px;
        padding-left: 29%;
    }

    .where-design .text {
        padding-right: 29%;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .where-design {
        padding-left: 54px;

        &.ar {
            padding-left: 0px;
            padding-right: 54px;
        }
    }

    .where-design h2 {
        font-size: 28px;
        margin-bottom: 90px;
    }

    .img-brands-imges {
        width: 88% !important;
        height: 262px !important;
    }

    .where-design.ar h2::after {
        margin-right: 0px;
    }

    .where-design h2::after {
        width: 37%;
        margin-left: 0px;
    }

    .where-design .text {
        padding-bottom: 90px;
    }

    .where-design .text p {
        font-size: 18px;
    }
}

@media all and (max-width:767px) {
    .where-design {
        padding-left: 39px;

        &.ar {
            padding-left: 0px;
            padding-right: 39px;
        }
    }

    .where-design h2 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .where-design.ar .text {
        padding-right: 0px;
        padding-left: 40px;
    }

    .where-design .text {
        padding-right: 40px;
        padding-bottom: 40px;
    }

    .where-design .text p {
        font-size: 10px;
        line-height: 20px;
    }

    .where-design.ar h2::after {
        margin-right: 0px;
    }

    .where-design h2::after {
        width: 19%;
        margin-left: 0px;
    }
}