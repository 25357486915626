.bringing-great.fitout {
    position: relative;
    background-color: transparent;
    /* background: var(--primery-color, #444484); */
}

.bringing-great.fitout h2 {
    color: white;

}

.bringing-great.fitout p {
    color: white;
}

.blur-animation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--primery-color, #444484);
}


@media all and (min-width:1540px) {
    .blur-animation {
        padding-bottom: 294px;
    }

    .space-planning-col {
        padding-right: 28px !important;
    }

    .space-planning-col p {
        width: 100%;
    }
}