.logos-con {
    padding-left: 6.6%;
}

.h2-line {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10%;
}

.h2-line::after {
    content: "";
    display: inline-block;
    height: 1px;
    background: #CCC;
    width: 100%;
    margin-left: 54px;
}

.brands-logo-con {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.brands-logo-con img {
    width: 10%;
    margin-right: 6.6%;
}

@media all and (min-width:1540px) {
    .logos-con {
        padding-left: 24.54%;

    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .logos-con {
        padding: 60px;
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .h2-line {
        font-size: 28px;
    }
}

@media all and (max-width:767px) {
    .logos-con {
        padding: 40px;
        padding-top: 0px;
        padding-right: 0px;
    }

    .h2-line::after {
        margin-left: 17px;
    }

    .h2-line {
        font-size: 16px;
    }
}