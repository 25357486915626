.our-team {
    padding-top: 126px;
    padding-bottom: 36px;
    padding-left: 6.6%;
    padding-right: 2.6%;
    background: #E9E9E9;

    &.ar {
        padding-left: 4.6%;
        padding-right: 6.6%;
    }
}

.our-team.ar h2,
.our-team.ar .card-team h5,
.our-team.ar .card-team p {
    font-family: "Rubik";
}

.our-team h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 47.727% */
    margin-bottom: 54px;
}

.our-team.ar .card-team {
    margin-right: 0px;
    margin-left: 0px;
}

.card-team {
    margin-right: 0px;
    margin-bottom: 126px;
}

.card-team img {
    width: 89%;
    height: 89%;
    margin-bottom: 35px;
}

.card-team h5 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    /* 33.6px */
    margin-bottom: 8px;
    width: 171px;
}

.card-team p {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 87.5% */
}

.card-team-con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media all and (min-width:1901px) {
    .our-team {
        padding-left: 31.4%;
        padding-right: 30%;

        &.ar {
            padding-left: 31.4%;
            padding-right: 30%;
        }
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .our-team {
        padding-left: 26%;
        padding-right: 23%;

        &.ar {
            padding-left: 23%;
            padding-right: 23%;
        }
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .our-team {
        padding: 90px;

        &.ar {
            padding: 90px !important;
        }
    }

}

@media all and (max-width:767px) {
    .our-team {
        padding: 40px;

        &.ar {
            padding: 40px !important;
        }
    }

    .our-team h2 {
        font-size: 22px;
        margin-bottom: 32px;
    }

    .our-team.ar .card-team {
        margin-right: 0px;
        margin-left: 2px !important;
    }

    .card-team {
        margin-right: 2px;
        margin-bottom: 25px;
    }

    .card-team img {
        width: 100%;
        height: 100%;
        margin-bottom: 25px;
    }

    .card-team h5 {
        font-size: 9.47px;
        margin-bottom: 4px;
    }

    .card-team p {
        font-size: 9.47px;
    }
}

.about-hero{
    margin-top: 50px;
}

.about-hero .text h2{
    margin-bottom: 50px;
}