.share-post-panner {

    margin: 5% 6.6%;
}

.share-post-panner .hero-new-case {
    margin: unset;
    height: 80vh;

}

.about-case-study .flex-end .linked-in {
    margin-right: 0px;
}

.about-case-study.ar .flex-end .linked-in {
    margin-right: 17px;
}

.about-case-study .flex-end {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 75px;
}

.about-case-study .flex-end h5 {
    color: var(--nutral-color, #CCC);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 17px;
}

.share-post-panner .date {
    width: 100%;
    text-align: right;
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 17px;
}

@media all and (min-width:1540px) {
    .share-post-panner {
        margin-left: 24.5%;
        margin-right: 24.5%;
    }

    .share-post-panner .hero-new-case {
        height: 50vh !important;
    }
}

@media all and (max-width:767px) {
    .about-case-study .flex-end h5 {
        font-size: 15.079px;
        margin-bottom: 0px;
        margin-right: 9px;
    }

    .share-post-panner .date {
        font-size: 9px;
        margin-top: 10px;
    }

    .share-post-panner {
        margin-left: 40px;
        margin-right: 40px;
    }

    .share-post-panner .hero-new-case {
        height: 200px;
    }
}