.brands-new-con {
  border-bottom: 1px solid #cccccc;
}

.brands-new-con .text-con-brands p {
  margin-bottom: 0px !important;
  line-height: 27px;
}

.logo-border {
  border: 2px solid #e9e9e9;
}

.new-brands-con {
  padding: 5% 6.6%;
  padding-bottom: 3%;
  border-bottom: 1px solid #cccccc;
}

.container-fluid.new-brands-con:last-of-type {
  border-bottom: 0px solid #ffffff !important;
}

.new-brands-con img {
  /* width: 40%; */
  height: 50px;
  margin-bottom: 54px;
}

.new-brands-con.ar p,
.new-brands-con.ar a {
  font-family: "Rubik";
}

.new-brands-con p {
  color: var(--primery-color, #444484);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 54px;
  line-height: 27px;
  margin-bottom: 54px;
}

.new-brands-con a {
  font-family: Roboto Slab;
  font-size: 23px;
  font-weight: 300;
  margin-right: 17px;
  color: #444484;
}

.new-brands-con .slide-img {
  background-image: url("../../Assets/Images/Rectangle\ 7.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.new-brands-con.ar .cx-slider {
  padding-right: 0 !important;
  padding-left: 6.6% !important;
}

.new-brands-con .cx-slider {
  width: 100%;
  height: 400px;
  padding-bottom: 0px !important;
  padding-right: 6.6%;
  padding-left: 0 !important;
  margin-top: 0px !important;
}

.new-brands-con .cx-slider .prev {
  left: -56px;
}

.new-brands-con.ar .cx-slider .prev {
  left: -15px;
}

.new-brands-con.ar .cx-slider .next {
  right: -56px;
}

.new-brands-con .cx-slider .next {
  right: -15px;
}

.new-brands-con .cx-slider .next,
.new-brands-con .cx-slider .prev {
  bottom: 0;
  top: unset;
}

.new-brands-con.ar .col-6:last-of-type {
  padding-left: 0px;
  padding-right: 6.6%;
}

.new-brands-con .col-6:last-of-type {
  padding-left: 6.6%;
}

.new-brands-con .cx-slider svg {
  color: #cccccc;
  width: 35px;
  height: 35px;
}

.new-brands-con .cx-slider .swiper {
  width: 100%;
  height: 100%;
}

.bordered-logo-con {
  border: 1px solid #ccc;
  width: 100%;
  height: 150px;
  padding: 10px;
  cursor: pointer;
}

.bordered-logo-con div {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  filter: grayscale(1)

}

.new-brands-con:last-of-type {
  border-bottom: unset !important;
}

.logo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 10px;
}

@media all and (min-width: 600px) {
    .logo-grid {
        grid-template-columns: repeat(4, 1fr);
      }

}

@media all and (min-width: 700px) {
    .logo-grid {
        grid-template-columns: repeat(5, 1fr);
      }

}

@media all and (min-width: 800px) {
    .logo-grid {
        grid-template-columns: repeat(6, 1fr);
      }

}

@media all and (min-width: 900px) {
    .logo-grid {
        grid-template-columns: repeat(7, 1fr);
      }

}

@media all and (min-width: 1000px) {
    .logo-grid {
        grid-template-columns: repeat(8, 1fr);
      }

}




@media all and (min-width: 1901px) {
  .new-brands-con.ar {
    padding-right: 29.2%;
  }

  .new-brands-con {
    padding-left: 31.54%;
    padding-right: 28.54%;
  }

  .new-brands-con img {
    height: 50px;
  }

  .new-brands-con .cx-slider .next {
    right: -15px;
  }
}

@media all and (min-width: 1540px) and (max-width: 1900px) {
  .new-brands-con {
    padding-left: 24.54%;
    padding-right: 24.54%;
  }

  .new-brands-con img {
    height: 50px;
  }

  .new-brands-con .cx-slider .next {
    right: -15px;
  }
}

@media all and (width: 1024px) {
  /* 
    .new-brands-con .col-12:first-of-type {
        height: 450px !important;
    } */

  .new-brands-con img {
    height: 55px !important;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .new-brands-con .col-12:first-of-type {
    margin-bottom: 20px;
    height: 350px;
  }

  .new-brands-con .cx-slider {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .new-brands-con.ar .cx-slider .next {
    right: -46px;
  }

  .new-brands-con .cx-slider .next {
    right: -41px;
  }

  .new-brands-con.ar .cx-slider .prev {
    left: -41px;
  }

  .new-brands-con .cx-slider .prev {
    left: -46px;
  }

  .new-brands-con img {
    height: 16%;
    margin-bottom: 20px;
  }

  .sss {
    padding-bottom: 50px;
  }

  .new-brands-con .cx-slider {
    height: 350px;
  }

  .new-brands-con img {
    height: 55px;
  }
}

@media all and (max-width: 767px) {
  .bordered-logo-con {
    height: 75px;
  }

  .sss {
    padding-top: 40px;
  }

  .new-brands-con {
    padding: 40px;
  }

  .new-brands-con .col-12:first-of-type {
    margin-bottom: 16px;
    height: 250px;
  }

  .new-brands-con .cx-slider {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 241px;
  }

  .new-brands-con.ar .cx-slider .next {
    right: -36px;
  }

  .new-brands-con .cx-slider .next {
    right: -33px;
  }

  .new-brands-con.ar .cx-slider .prev {
    left: -33px;
  }

  .new-brands-con .cx-slider .prev {
    left: -36px;
  }

  .new-brands-con .cx-slider svg {
    width: 25px;
    height: 25px;
  }

  .brands-new-con .text-con-brands p {
    line-height: 18px;
  }

  .new-brands-con img {
    /* width: 35%; */
    height: 30px;
    margin-bottom: 16px;
  }

  .new-brands-con p {
    font-size: 10px;
    line-height: 18px;
    width: 100%;
    margin-bottom: 16px;
  }

  .new-brands-con a {
    font-size: 15px;
    margin-right: 10px;
  }

  .new-brands-con a svg {
    width: 35.788px;
    height: 24.792px;
  }
}
