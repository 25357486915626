body{
  overflow-x: hidden;
}

.portfolio {
  padding-block: 50px;
  margin-block: 20px;
}
/* .profile article {
  width: 100%;
  padding-inline: 20px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}


.flipbook {
  width: 100vw;
  max-height: 80vh;
  background: #434485;
  border: 1px solid #434485;
}

.page {
  width: 100%;
  background-color: white;
  border: 1px solid #434485;
  text-align: center;
  line-height: 300px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page img {
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

.fp-iframe {
  min-height: 70vh;
  background-color: #fff;
}
.logo-backs2 {
  display: none !important;
  margin-top: 0;
}
.about-hero{
  padding-top: 0;
}
.videos h2{
  color: var(--primery-color, #444484);
  font-family: Roboto Slab;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 54px;
}

@media (min-width: 1200px) {
  .videos h2 {
      font-size: 2rem;
  }
}


.videos-list{
  display: grid;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 50px;
  grid-template-columns: repeat(1,1fr);
}

.videos-list a{
  width: 100%;
  /* border-radius: 10px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #434485;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.videos-list a .img{
  height: 150px;
  overflow: hidden;
  transition: all .3s ease-in-out;
  width: 100%;
  margin-bottom: 0;
}

.videos-list a .img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s ease-in-out;
}

@media (min-width: 400px) {
  .videos-list {
    grid-template-columns: repeat(2,1fr);
  }
}

@media (min-width: 700px) {
  .videos-list {
    grid-template-columns: repeat(3,1fr);
  }
}

@media (min-width: 900px) {
  .videos-list {
    grid-template-columns: repeat(4,1fr);
  }
}
.video-list a .description{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.video-list a .description span{
  font-size: 1.5rem;
  color: #434485;
  text-transform: uppercase;
}

.videos-list a:hover img{
  transform: scale(1.1);
}