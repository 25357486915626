.four-decade {
    padding-left: 0px;
    padding-right: 6.6%;

    &.ar {
        padding-left: 6.6%;
        padding-right: 0px;
    }
}

.four-decade.ar .title {
    font-family: "Rubik";
}

.four-decade .title {
    font-size: 30px;
    font-family: Roboto Slab;
    font-weight: 400;
    color: var(--primery-color, #444484);
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-bottom: 126px;
}

.four-decade.ar .title::before {
    margin-right: 0px;
    margin-left: 20px;
}

.four-decade .title::before {
    content: "";
    display: flex;
    justify-content: flex-end;
    width: 71%;
    height: 1px;
    background: #CCC;
    margin-right: 20px;
}

.four-decade .img {
    background-image: url("../../../Assets/Images/about.png");
    width: 85%;
    height: 68%;
    margin-left: 14.6%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



.four-decade.ar .img {
    margin-left: 0px;
    margin-right: 14.6%;
}

.four-decade .img._contain{
    width: 85%;
    height: 90%;
    background-size: contain;
    background-position-y: top;
}

@media (max-width: 767px) {
    .four-decade .img._contain {
        padding-left: 40px;
        padding-right: 40px;
        width: 73%;
        height: 250px;
        margin-bottom: 40px;
        margin-top: 40px;
        background-position-x: center;
        content: "";
    }
}

@media (max-width: 646px) {
    .four-decade .img._contain {
        height: 200px;
    }
}

@media (max-width: 518px) {
    .four-decade .img._contain {
        height: 160px;
    }
}

@media (max-width: 400px) {
    .four-decade .img._contain {
        height: 120px;
    }
}



@media (min-width: 1540px) and (max-width: 1900px) {
    .four-decade .img._contain{
        width: 53%;
        height: 75%;
    }
   
}


@media (min-width: 1901px) {
    .four-decade .img._contain{
        width: 29%;
    }
}

@media (min-width: 1901px) {
    .four-decade .img._contain{
        height: 62%;
        width: 29%;
    }
}


.four-decade.ar .text {
    padding-left: 0px;
    padding-right: 6.6%;
    font-family: "Rubik";
}

.four-decade .text {
    padding-left: 6.6%;
    padding-bottom: 126px;
}

.four-decade .text {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    /* 116.667% */
}

@media all and (min-width:1901px) {

    .four-decade {
        padding-right: 30.4%;

        &.ar {
            padding-right: 0px;
            padding-left: 30.4%;
        }
    }

    .four-decade.ar .img {
        margin-left: 0%;
        margin-right: 90.9%;
    }

    .four-decade .img {
        width: 29%;
        height: 62%;
        margin-left: 90.9%;

    }

    .four-decade.ar .text {
        padding-left: 0px;
        padding-right: 26.6%;
    }

    .four-decade .text {
        padding-left: 26.6%;
    }

    .four-decade .title::before {
        width: 67%;
        margin-right: 0px;
    }

    .where-design h2::after {
        width: 67%;
    }

}

@media all and (min-width:1540px) and (max-width:1900px) {

    .four-decade {
        padding-right: 23%;

        &.ar {
            padding-right: 0px;
            padding-left: 23%;
        }
    }

    .four-decade.ar .img {
        margin-left: 0%;
        margin-right: 65.9%;
    }

    .four-decade .img {
        width: 53%;
        height: 75%;
        margin-left: 65.9%;

    }

    .four-decade.ar .text {
        padding-left: 0px;
        padding-right: 26.6%;
    }

    .four-decade .text {
        padding-left: 26.6%;
    }

    .four-decade .title::before {
        width: 67%;
        margin-right: 0px;
    }

    .where-design h2::after {
        width: 67%;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .four-decade {
        padding-right: 0px;

        &.ar {
            padding-left: 0px;
        }
    }

    .four-decade.ar .title {
        padding-right: 0px;
        padding-left: 54px;
    }

    .four-decade .title {
        padding-right: 54px;
        margin-bottom: 90px;
        font-size: 28px;
    }

    .four-decade.ar .title::before {
        margin-left: 0px;
    }

    .four-decade .title::before {
        margin-right: 0px;
        width: 51%;
    }

    .four-decade.ar .img {
        padding-right: 40px;
        padding-left: 0px;
    }

    .four-decade .img {
        padding-left: 40px;
        padding-right: 00px;
        width: 80%;
        height: 30%;

    }

    .four-decade .img img {
        width: 100%;
        height: 100%;
    }

    .four-decade.ar .text {
        padding-inline: 34px !important;
    }

    .four-decade .text {
        padding-left: 34px;
        padding-right: 34px;
        font-size: 12px;
    }

    .row-decade {
        margin: 0px;
    }

}

@media all and (max-width:767px) {
    .four-decade {
        padding-right: 0px;

        &.ar {
            padding-left: 0px;
        }
    }

    .four-decade.ar .title {
        padding-right: 0px;
        padding-left: 54px;
    }

    .four-decade .title {
        padding-right: 54px;
        margin-bottom: 16px;
        font-size: 16px;
    }

    .four-decade.ar .title::before {
        margin-left: 0px;
    }

    .four-decade .title::before {
        margin-right: 0px;
        width: 36%;
    }

    .four-decade.ar .img {
        padding-inline: 40px !important;
    }

    .four-decade .img {
        padding-left: 40px;
        padding-right: 40px;
        width: 73%;
        height: 250px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .row-decade .text p {
        line-height: 20px;
    }

    .four-decade .img img {
        width: 100%;
        height: 100%;
    }

    .four-decade.ar .text {
        padding-inline: 40px !important;
    }

    .four-decade .text {
        margin-top: 16px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 90px;
        font-size: 10px;
    }

    .row-decade {
        margin: 0px;
    }
}