.hero-new-case {
    background-image: url("../../Assets/Images/Rectangle\ 7.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 80vh;
    margin: 5% 6.6%;
}

.about-case-study {
    padding-bottom: 5%;
    padding-left: 6.6%;
    padding-right: 6.6%;
    padding-bottom: 5%;
}

.about-case-study.ar .col-md-7 {
    padding-left: 0px;
    padding-right: 5%;
}

.about-case-study .col-md-7 {
    padding-left: 5%;
}

.about-case-study-2 p {
    width: 85%;
}

.about-case-study-2 .col-md-7 .img,
.about-case-study-2 .col-md-5 .img {
    background-image: url("../../Assets/Images/Rectangle\ 7.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 500px;
    width: 100%;
}

.about-case-study-2.ar .col-md-7 .img,
.about-case-study-2.ar .col-md-7 {
    padding-right: 5%;
    padding-left: 0px;
}

.about-case-study-2 .col-md-7 .img {
    padding-left: 5%;
}

.about-case-study-2 p {
    width: 100%;
}

.about-case-study-2 .col-md-7 {
    padding-left: 5%;
}

.about-case-study-2.ar .col:first-of-type {
    padding-right: 0px;
    padding-left: 5%;
}

.about-case-study-2 .col:first-of-type {
    padding-right: 5%;
}

.about-case-study.ar h5,
.about-case-study.ar h6,
.about-case-study.ar h2,
.about-case-study.ar h3,
.about-case-study.ar h1,
.about-case-study.ar h4,
.about-case-study.ar .first-p,
.about-case-study.ar p {
    font-family: "Rubik";
}

.about-case-study h5 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.about-case-study h6 {
    color: #444484;
    font-family: 'Roboto Slab';
    font-weight: 300;
    font-size: 30px;
    margin-bottom: 54px;
}

.about-case-study h2,
.about-case-study h3 {
    color: #444484;
    font-family: 'Roboto';
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 25px;
}

.about-case-study .first-p {
    width: 85%;
    font-family: 'Roboto';
    font-weight: 300;
    line-height: 30px;
    color: #444484;
    font-size: 22px;
}


.about-case-study p {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    color: #444484;
}

.about-case-study p {
    margin-bottom: 25px;
}

@media all and (min-width:1901px) {
    .hero-new-case {
        height: 50vh;
        margin-left: 31.1%;
        margin-right: 29.9%;
    }

    .about-case-study {
        padding-left: 31.2%;
        padding-right: 28.5%;
    }

    .about-case-study.ar {
        padding-right: 30%;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .hero-new-case {
        height: 50vh;
        margin-left: 24.5%;
        margin-right: 24.5%;
    }

    .about-case-study {
        padding-left: 24.5%;
        padding-right: 24.5%;
    }
}

@media all and (min-width:768px) and (max-width:1024px) {
    .hero-new-case {
        height: 400px;
    }

    .about-case-study h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .about-case-study h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .about-case-study .first-p,
    .about-case-study p {
        font-size: 14px;
        line-height: 25px;
    }

    .about-case-study p {
        margin-bottom: 15px;
    }

    .about-case-study-2 .col-md-7 .img,
    .about-case-study-2 .col-md-5 .img {
        height: 400px;
    }

    .about-case-study h5 {
        font-size: 18px;
        margin-bottom: 15px;
    }
}

@media all and (max-width:767px) {
    .about-case-study h6 {
        font-size: 16px;
    }

    .hero-new-case {
        height: 200px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .about-case-study {
        padding-left: 40px;
        padding-right: 40px;
    }

    .about-case-study h2 {
        font-size: 12px;
        margin-bottom: 16px;
    }

    .about-case-study h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .about-case-study .first-p,
    .about-case-study p {
        font-size: 12px;
        line-height: 21px;
    }

    .about-case-study h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .about-case-study.ar .col-md-7 {
        padding-left: 0px;
        padding-right: 4%;
    }

    .about-case-study .col-md-7 {
        padding-left: 4%;
    }

    .about-case-study-2 .col-md-5 {
        order: 2;
    }

    .about-case-study-2 .col-md-7 {
        order: 1;
    }

    .about-case-study-2.ar .col-md-7 .img {
        padding-left: 0px;
        padding-right: 4%;
    }

    .about-case-study-2 .col-md-7 .img {
        padding-left: 4%;
        height: 250px;
        margin-bottom: 20px;
    }

    .about-case-study-2 .col-md-5 .img {
        height: 200px;
    }
}