.Challenges {
    padding-bottom: 126px;
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.Challenges h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 54px;
}

.Challenges p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.Challenges p:not(:last-of-type) {
    margin-bottom: 126px;
}

.Challenges img {
    margin-left: 54px;
    width: 100%;
    height: 100%;
}

.Results {
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.Results h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 54px;
}







.next-case-study {
    border-top: 1px solid #ccc;

}

.next-case-study-col {
    border-left: 1px solid #ccc;
    padding-left: 54px;
    padding-top: 126px;
    padding-bottom: 126px;
}

.next-case-study-col img {
    width: 40%;
    margin-right: 54px;
}

.cx-slider {
    padding-left: 6% !important;
    padding-right: 6% !important;
}


.next-case-study-col .text h4 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30.141px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 32px;
}

.next-case-study-col .text p {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 20.106px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.next-case-study-col svg {
    width: 30px;
    height: 30px;
    margin-left: 30px;
}

.details {
    margin-bottom: 126px;
}

.col-img-challenges {
    display: flex;
    justify-content: flex-end;
}

.details-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 512px;
    padding-left: 54px;
    width: 90%;
}

@media all and (min-width:1540px) {

    .Challenges,
    .Results {
        padding-left: 24.6%;
        padding-right: 24.6%;
    }

}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .Challenges {
        padding-bottom: 90px;
    }

    .Challenges .o-2 {
        order: 2;
    }

    .details {
        margin-bottom: 90px;
    }

    .Challenges .o-1 {
        order: 1;
    }

    .Challenges img {
        margin-left: 0px;
    }

    .Challenges .text {
        margin-top: 32px;
    }

    .Challenges h2 {
        font-size: 26px;
        margin-bottom: 32px;
    }

    .Challenges p:not(:last-of-type) {
        margin-bottom: 32px;
    }

    .Challenges p {
        font-size: 12px;
    }

    .Results h2 {
        font-size: 26px;
        margin-bottom: 40px;
    }

    .Results {
        margin-top: 40px;
    }

    .cx-prev .text h4,
    .prev-cx .text h4 {
        margin-bottom: 10px;
    }

    .details-img {
        width: 100%;
    }

}

@media all and (max-width:767px) {
    .details-img {
        width: 100%;
    }

    .details {
        margin-bottom: 40px;
    }

    .Challenges .o-2 {
        order: 2;
    }

    .Challenges .o-1 {
        order: 1;
    }

    .Challenges {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 0;
    }

    .Challenges img {
        margin-left: 0px;
    }

    .Challenges .text {
        margin-top: 16px;
    }

    .cx-slider {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }

    .details-img {

        height: 239px;
    }

    .Challenges h2 {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .Challenges p:not(:last-of-type) {
        margin-bottom: 16px;
    }

    .Challenges p {
        font-size: 10px;
    }

    .Results h2 {
        font-size: 18px;
        margin-bottom: 40px;
        margin-left: 40px;
    }

    .Results {
        margin-top: 40px;
    }
}