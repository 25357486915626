.bringing-great {
    position: relative;
    background-color: transparent;
    height: 253px;
    width: 89%;
    margin-top: 126px;
    padding-top: 54px;
    padding-left: 47px;
    margin-bottom: 126px;
    margin-left: 6.6%;

    &.ar {
        margin-left: unset;
        margin-right: 6.6%;
        padding-left: unset;
        padding-right: 47px;
    }
}

.blur-animation-design {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #E9E9E9;
}

.space-planning-con {
    margin-left: 6.6%;

    &.ar {
        margin-left: unset;
        margin-right: 6.6%;
    }
}

.bringing-great.ar h2,
.bringing-great.ar p {
    font-family: "Rubik";
}

.bringing-great h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}

.bringing-great p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    width: 94%;
}

@media all and (min-width:1901px) {
    .blur-animation-design {
        padding-bottom: 294px;
    }

    .bringing-great {
        width: 38%;
        margin-left: 0px;

        &.ar {
            margin-right: 0px;
        }
    }

    .BringingGreat-con-gre {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .space-planning-con {
        width: 38%;
        margin-left: auto !important;

        &.ar {
            margin-right: auto !important;
        }
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .blur-animation-design {
        padding-bottom: 294px;
    }

    .bringing-great {
        width: 51%;
        margin-left: 0px;

        &.ar {
            margin-right: 0px;
        }
    }

    .BringingGreat-con-gre {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .space-planning-con {
        width: 51%;
        margin-left: auto !important;

        &.ar {
            margin-right: auto !important;
        }
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .bringing-great {
        margin: 40px !important;
        padding: 54px 47px;
        height: 100%;
        width: 85%;
    }

    .bringing-great h2 {
        font-size: 26px;
        margin-bottom: 40px;
    }

    .bringing-great p {
        font-size: 20px;
        line-height: 30px;
    }

}

@media all and (max-width:767px) {
    .bringing-great {
        margin: 40px !important;
        padding: 21px !important;
        height: 100%;
        width: 85%;

        &.ar {
            width: 80%;
        }
    }

    .bringing-great p {
        font-size: 9px;
        line-height: 20px;

    }

    .bringing-great h2 {
        font-size: 10.174px;
        margin-bottom: 20px;
    }
}