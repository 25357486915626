.link-project-con {
    border-bottom: 1px solid rgb(193, 192, 192);
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 54px;

    &.ar {
        padding-right: unset;
        padding-left: 54px;
    }
}

.link-project-con.ar .link-project {
    margin-left: 0px;
    margin-right: 2px;
}

.link-project-con .link-project {
    margin-bottom: 0px;
    margin-left: 2px;
}

.link-project-con ul {
    margin: 0;
}

.link-project-con.ar ul li {
    font-family: "Rubik";
}

.link-project-con ul li {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    display: inline;
    cursor: pointer;

}

.link-project-con ul li.active {
    color: var(--primery-color, #444484);
    font-weight: 400;
}

.link-project-con.ar ul li:not(:last-child) {
    margin-right: 0px;
    margin-left: 20px;
}

.link-project-con.ar ul li:last-child {
    margin-left: 40px;
}

.link-project-con ul li:not(:last-child) {
    margin-right: 20px;
}

.projects-con {
    padding-top: 126px;
    padding-left: 6.6%;

    &.ar {
        padding-left: 0px;
        padding-right: 6.6%;
    }
}

.projects-con.ar ul,
.projects-con.ar .card-project {
    font-family: "Rubik";
}

.projects-con ul {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 25px;
    font-weight: 200;
    margin-bottom: 171px;
}

.projects-con ul li {
    display: inline;
}

.projects-con.ar ul li:not(:last-child) {
    margin-left: 54px;
    margin-right: 0px;
}

.projects-con ul li:not(:last-child) {
    margin-right: 54px;
}

.active {
    font-weight: 400;
}

.card-con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-project:hover p {
    color: #444484;
    transition: .4s;
}

.projects-con .col {
    margin-bottom: 157px;
}

.card-project {
    color: var(--nutral-color, #CCC);
    font-family: Roboto;

    /* margin-right: 110px; */
}

.card-project:nth-child(4n) {
    margin-right: 0px;
}


.card-project .img-card {
    padding: 34% 10%;
    width: 80%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-project img {
    width: 242px;
    height: 233.838px;
    margin-bottom: 16.5px;
}

.card-project p {
    font-size: 18.486px;
    font-weight: 400;
}

.card-project {
    cursor: pointer;
}

.card-project .img-card {
    margin-bottom: 16px;
}

@media all and (min-width:1901px) {
    .link-project-con.ar ul li:last-child {
        margin-left: 0px;
    }

    .projects-con {
        width: 69%;
        padding-left: 15.5%;
        padding-right: 11.3%;

        &.ar {
            padding-right: 15.5%;
            padding-left: 12.9%;
        }
    }

    .link-project-con.ar ul {
        margin-left: 22.5%;
        margin-right: 0px;
    }

    .link-project-con ul {
        margin-right: 22.1%;
    }

    .card-project .img-card {
        padding: 31% 7%;
        width: 81%;
    }

    .projects-con .col {
        margin-bottom: 100px;
    }

    /* .card-project {
        width: 100%;
    }

    .card-project .img-card {
        width: 100%
    } */

}

@media all and (min-width:1540px) and (max-width:1900px) {
    .link-project-con.ar ul li:last-child {
        margin-left: 0px;
    }

    .projects-con {
        width: 69%;
        padding-left: 9.4%;
        padding-right: 6%;

        &.ar {
            padding-right: 8.5%;
            padding-left: 6%;
        }
    }

    .link-project-con.ar ul {
        margin-left: 22.5%;
        margin-right: 0px;
    }

    .link-project-con ul {
        margin-right: 22.1%;
    }

    .card-project .img-card {
        padding: 31% 7%;
        width: 81%;
    }

    .projects-con .col {
        margin-bottom: 100px;
    }

    /* .card-project {
        width: 100%;
    }

    .card-project .img-card {
        width: 100%
    } */

}

@media all and (max-width:767px) {
    .link-project-con.ar ul li:last-child {
        margin-left: 0px;
    }

    .card-project .img-card {
        width: 100%;
        height: 102px;
        margin-bottom: 8px;
    }

    .projects-con .col {
        margin-bottom: 0px;
    }

    .projects-con {
        padding: 54px;
        padding-bottom: 0px;

        &.ar {
            padding: 44px !important;
        }
    }

    .card-project img {
        width: 100%;
        height: 100%;
    }

    .card-project {
        margin-bottom: 54px;
        margin-right: 0px;
    }

    .card-project p {
        font-size: 12.92px;
        margin-bottom: 0px;
    }

    .link {
        padding-bottom: 0px;
        margin-left: 8px;
        padding-top: 0px;

        &.ar {
            margin-left: unset;
            margin-right: 8px;
        }
    }

    .link-project-con {
        padding-bottom: 16px;
        flex-direction: column;
        align-items: flex-start;
    }

    .link-project-con .link-project {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .link-project-con.ar ul {
        padding-left: unset;
        padding-right: 38px;
    }

    .link-project-con ul {
        padding-left: 38px;
        margin-top: 20px;
    }

    .link-project-con ul li {
        font-size: 12px;

    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .link-project-con ul li {
        font-size: 15px;
    }

    .projects-con .col {
        margin-bottom: 0px;
    }

    .link-project-con.ar ul li:last-child {
        margin-left: 0px;
    }

    .card-project .img-card {

        width: 100%;
    }

    .projects-con {
        padding: 154px 54px;
        padding-bottom: 80px;

        &.ar {
            padding: 154px 44px !important;
        }
    }

    .card-project img {
        width: 100%;
        height: 100%;
    }

    .card-project {
        margin-bottom: 54px;
        margin-right: 0px;
    }

    .card-project p {
        font-size: 22.64px;
        margin-bottom: 0px;
    }

    .link-project-con {
        padding-bottom: 20px;
    }

    .link-project-con .link-project {
        margin-bottom: 0px;
    }

    .link {
        padding-bottom: 0px;
        margin-left: 8px;
        padding-top: 30px;
        font-size: 30px;
    }

}