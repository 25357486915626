.Sample-Img {
    padding: 126px 90px;
    position: relative;
}

.slide-img {
    background-image: url("../../../Assets/Images/Rectangle\ 13.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 650px;
}


.swiper-pagination {
    display: flex;
    justify-content: flex-end;
    position: static;
    margin-top: 25px;

}

.swiper-pagination-clickable .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    background: var(--nutral-color, #CCC);
    border-radius: 0px;
}

.next,
.prev {
    cursor: pointer;
    color: rgb(174, 174, 174);
}

.next:hover,
.prev:hover {
    color: rgb(123, 122, 122);
    transition: .3s;
}

.next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media all and (max-width: 767px) {
    .Sample-Img {
        padding: 40px;
        position: relative;
    }

    .slide-img {
        height: 146px;
    }

    .swiper-pagination {
        display: none;
    }

    .Sample-Img .next .icon {
        width: 25px;
        height: 25px;

    }

    .Sample-Img .prev .icon {
        width: 25px;
        height: 25px;

    }

    .Sample-Img .next {
        right: 10px;
    }

    .Sample-Img .prev {
        left: 10px;
    }


}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .Sample-Img {
        padding: 126px 38px;
        position: relative;
    }

    .swiper-pagination {
        display: none;
    }

    .slide-img {
        height: 349px;
    }

    .Sample-Img .next .icon {
        width: 40px;
        height: 40px;

    }

    .Sample-Img .prev .icon {
        width: 40px;
        height: 40px;

    }

}