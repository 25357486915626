.design-jabyriah {
    width: 100%;
    background: #E9E9E9;
    height: 100%;
    padding-top: 104px;
    padding-bottom: 104px;
    padding-left: 107px;

    &.ar {
        padding-left: unset;
        padding-right: 107px;
    }
}

.design-jabyriah.ar h2 svg {
    transform: rotateY(180deg);
    margin-left: unset;
    margin-right: 17px;
}

.design-jabyriah h2 svg {
    width: 40px;
    height: 40px;
    margin-left: 17px;
}

.design-jabyriah h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.design-jabyriah.ar h2,
.design-jabyriah.ar p,
.design-jabyriah.ar .flex-aswad-end h6 {
    font-family: "Rubik";
}

.design-jabyriah p {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 56px;
}

.design-jabyriah .flex-aswad-end {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.design-jabyriah .flex-aswad-end h6 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-right: 36px;
}

@media all and (min-width:1540px) {
    .design-jabyriah {
        height: 100%;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .design-jabyriah {
        padding: 90px;
        height: 100%;

        &.ar {
            padding: 90px !important;
        }
    }

    .design-jabyriah h2 {
        font-size: 26px;

    }

    .design-jabyriah h2 svg {
        width: 20px;
        height: 20px;
        margin-left: 17px;
    }

}

@media all and (max-width:767px) {
    .design-jabyriah {
        padding: 40px;
        height: 100%;

        &.ar {
            padding: 40px !important;
        }
    }

    .design-jabyriah h2 {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .design-jabyriah h2 svg {
        width: 20px;
        height: 20px;
        margin-left: 17px;
    }

    .design-jabyriah p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .design-jabyriah .flex-aswad-end h6 {
        font-size: 16px;
    }

    .design-jabyriah .flex-aswad-end svg {
        width: 30px;
        height: 30px;
    }
}