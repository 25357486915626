.about-us {
    height: 70vh;
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;

    &.ar {
        font-family: "Rubik";
    }
}

.about-us .row {
    height: 100%;
}

.about-us.ar h4 {
    font-family: "Rubik";
}

.about-us .col-md-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us p {
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 67px;
    width: 79%;
}

.about-us.ar .about-arrow-con {
    margin-right: unset;
    margin-left: 89px;
}

.new-brands-con.ar .about-arrow-con.ar {
    margin-right: unset;
    margin-left: 89px;
}


.about-arrow-con {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 89px;
}

.about-us.ar .about-arrow-con svg,
.new-brands-con.ar .about-arrow-con.ar svg {
    transform: rotateY(180deg);
}

.about-us.ar .about-arrow-con h4 {
    margin-right: unset;
    margin-left: 17px;
}

.about-arrow-con h4 {
    font-family: Roboto Slab;
    font-size: 36px;
    font-weight: 300;
    margin-right: 17px;
}

.read-more {
    background: #E9E9E9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 150px;
    padding-right: 150px;
}

.read-more h3 {
    font-size: 36px;
    font-weight: 400;
}

.read-more h6 {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 86px;
}

.about-arrow-con svg {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

@media all and (max-width: 768px) {
    .about-us {
        height: 41vh;
    }

    .about-us .col-md-5 {
        align-items: flex-start;
        padding-bottom: 10px;
        padding-left: 38px;
        padding-right: 38px;
    }

    .about-us p {
        font-size: 12px;
        margin-bottom: 18px;
    }

    .about-arrow-con h4 {
        font-size: 15.157px;
        margin-right: 10px;
    }

    .about-arrow-con svg {
        width: 35.788px;
        height: 24.792px;
    }

    .read-more {
        padding-left: 42px;
        padding-right: 42px;
    }

    .read-more h3 {
        font-size: 17.974px;
    }

    .read-more h6 {
        font-size: 13.481px;
        margin-bottom: 18px;
    }
}

@media only screen and (min-device-width : 767px) and (max-device-width : 1024px) {
    .about-us p {
        font-size: 15px;
        margin-bottom: 45px;
        width: 100%;
    }

    .about-arrow-con h4 {
        font-size: 26px;
        margin-right: 10px;
    }

    .about-arrow-con svg {
        width: 35.788px;
        height: 24.792px;
    }

    .about-us .col-md-5 {
        align-items: flex-start;

        padding-left: 44px;
    }

    .read-more h3 {
        font-size: 26px;
        margin-right: 10px;

    }

    .read-more {
        padding-left: 101px;
        padding-top: 90px;
        padding-bottom: 75px;
        padding-right: 0px;
    }

    .about-arrow-con {
        margin-right: 0px;
    }

    .about-us {
        height: 100%;
    }

    .read-more h6 {
        margin-bottom: 45px;
        font-size: 24px;
    }
}