.new-product {
    padding: 0px;
    height: 70vh;
}

.new-product .row {
    height: 100%;
}

.new-product .row .col-md-7 {
    height: 100%;
}

@media all and (max-width: 767px) {
    .new-product {
        padding: 0px 35px;
        border-bottom: 1px solid #ccc;
        height: 100%;
    }

    .new-product .row .col-md-7 {
        height: 200px;
    }

    .new-product .w {
        display: flex;
        justify-content: flex-start;
    }

    .new-product .w img {
        width: 100%;
    }

    .new-product .col-md-5 {
        justify-content: flex-start;
        padding: 0px;
        margin-bottom: 32px;
    }
}

@media all and (min-width:2500px) {
    .new-product .row .col-md-7 {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .new-product {
        height: 45vh;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .new-product .w {
        order: 1;
        height: 350px;
    }

    .new-product {
        height: 30vh;
    }

    .new-product .col-md-5 {
        order: 2;
    }
}